const client_secret = "1fda996e-19fd-416c-8a7c-098277ddb939";

const addUser = async (email: string, type: string, channel: string) => {
  const url = "https://monkfish-app-o43a4.ondigitalocean.app/api/v0/users/add";
  const data = {
    email: email,
    type: type,
    channel: channel,
  };

  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      client_secret,
    },
    body: JSON.stringify(data),
  });

  return response;
};

const addVisit = async (page: string) => {
  const url = `https://monkfish-app-o43a4.ondigitalocean.app/api/v0/visits/add/${page}`;
  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      client_secret,
    },
  });

  return response;
};

export { addUser, addVisit };
