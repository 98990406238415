import React from "react";
import { Route, HashRouter as Router, Routes } from "react-router-dom";

import Landing from "./pages/Landing.tsx";
import Onboarding from "./pages/Onboarding.tsx";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="/onboarding" element={<Onboarding />} />
      </Routes>
    </Router>
  );
}

export default App;
