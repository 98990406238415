import React from "react";
import "./Navbar.css";

const Navbar = () => {
  return (
    <nav className="navbar">
      <div className="navbar-container">
        <div className="navbar-brand">
          <a href="/#/" className="navbar-logo">
            Band Bridge
          </a>
        </div>
        <div className="navbar-menu">
          <ul className="navbar-nav">
            <li className="nav-item">
              <a href="/#/" className="nav-link">
                Home
              </a>
            </li>
            <li className="nav-item">
              <a href="/#/onboarding" className="nav-link nav-cta">
                Join
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
