import React, { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { addVisit } from "../api.ts";
import logo from "../assets/logo.png";
import Navbar from "../components/Navbar.tsx";
import "./Landing.css";

const Landing = () => {
  const location = useLocation();
  const isFirstVisit = useRef(true);

  useEffect(() => {
    if (isFirstVisit.current) {
      addVisit("landing");
      isFirstVisit.current = false;
    }
  }, [location]);

  return (
    <div className="landing-container">
      <Navbar />
      <header className="header">
        <img src={logo} alt="Logo" className="logo" />
        <h1 className="main-heading">Band Bridge</h1>
      </header>
      <main className="content">
        <p className="description">
          BandBridge is a place where concertgoers can find local artists and
          venues.
        </p>
        <a href="/#/onboarding" className="cta-button">
          Join Today!
        </a>
        <div className="testimonial">
          <blockquote>
            "BandBridge helped me find my new favorite band!"
          </blockquote>
          <cite>- Miles Herrman, Cal Poly Student</cite>
        </div>
      </main>
      <footer className="footer">
        <p className="contact">
          Check us out on Instagram:{" "}
          <a
            href="https://www.instagram.com/band.bridge/"
            target="_blank"
            rel="noreferrer"
          >
            instagram.com
          </a>
        </p>
        <p className="contact">
          For more information, contact us at:{" "}
          <a href="mailto:gflusser@calpoly.edu">gflusser@calpoly.edu</a>
        </p>
      </footer>
    </div>
  );
};

export default Landing;
