import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { addUser, addVisit } from "../api.ts";
import Navbar from "../components/Navbar.tsx";
import "./Onboarding.css";

const Onboarding = () => {
  const location = useLocation();
  const isFirstVisit = useRef(true);

  const [email, setEmail] = useState<string>("");
  const [type, setType] = useState("concertgoer");
  const [channel, setChannel] = useState("flyer");

  useEffect(() => {
    if (isFirstVisit.current) {
      addVisit("landing");
      isFirstVisit.current = false;
    }
  }, [location]);

  const handleAddUser = async () => {
    console.log("Email:", email, "Type:", type, "Channel:", channel);
    const trimmedEmail = email.trim();

    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!emailPattern.test(trimmedEmail)) {
      alert("Please enter a valid email address.");
      return;
    }

    try {
      const response = await addUser(trimmedEmail, type, channel);
      console.log("Response:", response);
      alert("Thank you for joining our mailing list!");
      window.location.href = "/";
    } catch (error) {
      console.error("Error adding user:", error);
      alert(
        "There was an error adding you to the mailing list. Please try again."
      );
    }
  };

  return (
    <div className="onboarding-container">
      <Navbar />
      <header className="header">
        <h1 className="main-heading">Join our mailing list</h1>
      </header>
      <main className="onboarding-content">
        <div className="main-container">
          <p className="description">Get notified when we launch!</p>
          <div className="form-group">
            <input
              type="email"
              name="email"
              placeholder="Email address"
              className="form-input"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <select
              name="type"
              className="form-select"
              value={type}
              onChange={(e) => setType(e.target.value)}
            >
              <option value="concertgoer">Concertgoer</option>
              <option value="artist">Artist</option>
              <option value="venue">Venue</option>
            </select>
          </div>
          <div className="form-group">
            <select
              name="channel"
              className="form-select"
              value={channel}
              onChange={(e) => setChannel(e.target.value)}
            >
              <option value="flyer">Flyer</option>
              <option value="word-of-mouth">Word of Mouth</option>
              <option value="social-media">Social Media</option>
              <option value="other">Other</option>
            </select>
          </div>
          <button onClick={handleAddUser} className="cta-button">
            Join
          </button>
        </div>
      </main>
      <footer className="footer">
        <p className="contact">
          For more information, contact us at:{" "}
          <a href="mailto:gflusser@calpoly.edu">gflusser@calpoly.edu</a>
        </p>
      </footer>
    </div>
  );
};

export default Onboarding;
